import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./App.scss";
import { Unity, useUnityContext } from "react-unity-webgl";

const ratio = 1620 / 1080;

function App() {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const [size, setSize] = useState([810, 540]);
  useLayoutEffect(() => {
    function updateSize() {
      let height = 810;
      let width = 540;
      if (window.innerWidth / window.innerHeight > ratio) {
        height = window.innerHeight;
        width = Math.round(height * ratio);
      } else {
        width = window.innerWidth;
        height = Math.round(width / ratio);
      }

      if (width > 1240) {
        width = 1240;
        height = Math.round(width / ratio);
      }

      setSize([width, height]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } =
    useUnityContext({
      loaderUrl: "tcg/Build/tcg.loader.js",
      dataUrl: "tcg/Build/tcg.data.unityweb",
      frameworkUrl: "tcg/Build/tcg.framework.js.unityweb",
      codeUrl: "tcg/Build/tcg.wasm.unityweb",
    });

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        console.log(window.devicePixelRatio);
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );
  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <div className="container">
        {isLoaded === false && (
          <div
            className="lodading-overlay"
            style={{ width: size[0], height: size[1] }}
          >
            <p>Loading... ({loadingPercentage}%)</p>
          </div>
        )}

        {isLoaded && (
          <button
            className="full-screen-button"
            onClick={() => {
              requestFullscreen(true);
            }}
          >
            <img src="./full-screen-icon.png" alt="full-screen" />
          </button>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ width: size[0], height: size[1] }}
          devicePixelRatio={devicePixelRatio}
        />
    </div>
  );
}

export default App;
